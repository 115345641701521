<template>
  <div>
    <h4>Especes</h4>
    
    <data-table
      :data-source="sortedEspeces"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          @click.prevent="initForm"
          data-toggle="modal"
          data-target="#add-espece"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>
    <modal
      title="Ajout d'espèce"
      id="add-espece"
    >
      <form
        class="form-horizontal"
        @submit.prevent="addingEspece"
      >
        <div class="form-group">
          <label for="add-libelle">Libelle</label>
          <input
            type="text"
            id="add-libelle"
            name="add-libelle"
            v-model="libelle"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <label for="add-code">Code</label>
          <input
            type="text"
            id="add-code"
            name="add-code"
            v-model="code"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="active"
              id="add-active"
            >
            <label
              class="form-check-label"
              for="add-active"
            >
              Activer ?
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Ajouter
            </button>
          </div>
        </div>
      </form>
      <template slot="footer">
        <div />
      </template>
    </modal>
    <modal
      title="Modification de l'espèce"
      id="update-espece"
    >
      <form
        class="form-horizontal"
        @submit.prevent="editingEspece"
      >
        <div class="form-group">
          <label for="edit-libelle">Libelle</label>
          <input
            type="text"
            id="edit-libelle"
            name="edit-libelle"
            v-model="libelle"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <label for="edit-code">Code</label>
          <input
            type="text"
            id="edit-code"
            name="edit-code"
            v-model="code"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="active"
              id="update-active"
            >
            <label
              class="form-check-label"
              for="update-active"
            >
              Activer ?
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </form>
      <template slot="footer">
        <div />
      </template>
    </modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import {ADD_ESPECE, EDIT_ESPECE } from '../../../graphql/identification'
import DataTable from '../../../components/dataTable/local.vue'
import {TEXT_TYPE, COMPONENT_TYPE, BOOLEAN_TYPE } from '../../../components/dataTable/dataType'
import Modal from '../../../components/modal.vue'
const Actions = () => import('../../../components/admin/identification/especeAction.vue')
export default {
    components: { DataTable, Modal},
    data(){
        return {
            libelle: null,
            code: null,
            active: false,
            has_error: false,
            error_msg: null
        }
    },
    watch: {
        selectedEspece: {
            handler(){
                if(this.selectedEspece !== null) {
                    this.libelle = this.selectedEspece.libelle
                    this.code = this.selectedEspece.code
                    this.active = (this.selectedEspece.active === null)? false : this.selectedEspece.active 
                }
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations({
            done: 'DONE',
            setSelectedEspece: 'SET_SELECTED_OBJECT'
        }),
        initForm(){
            this.setSelectedEspece(null)
            this.libelle = null
            this.code = null
        },
        addingEspece(){
            let data = {
                libelle: this.libelle,
                code: this.code,
                active: this.active
            }
            this.$apollo.mutate({
                mutation: ADD_ESPECE,
                variables: {
                    "espece": {
                        ...data
                    }
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Espece add successfully on uid ${d.addEspece}`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        },
        editingEspece(){
            let data = {
                libelle: this.libelle,
                code: this.code,
                active: this.active
            }
            this.$apollo.mutate({
                mutation: EDIT_ESPECE,
                variables: {
                    "espece": {
                        ...data
                    }, 
                    "uid": this.selectedEspece.uid
                },
                update: () => {
                    console.log(`Espece  ${this.selectedEspece.libelle} edit successfully on uid ${this.selectedEspece.uid}`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        }
    },
    computed: {
        ...mapGetters({
            especes: 'identification/especes',
            selectedEspece: 'selectedObject'
        }),
        sortedEspeces(){
          return [...this.especes].sort((a,b) => a.libelle.localeCompare(b.libelle))
        },
        headers(){
            return [
                {label: 'ID', name: 'uid', type: TEXT_TYPE},
                {label: 'Libelle', name: 'libelle', type: TEXT_TYPE},
                {label: 'Code', name: 'code', type: TEXT_TYPE},
                {label: 'Active', name: 'active', type: BOOLEAN_TYPE},
                {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions},
            ]
        }
    }
}
</script>

<style>

</style>